import React, { useState, useEffect } from 'react';
import LoaderComponent from '../common/loader';
import { getWorkoutFrequency, getWorkoutPerformance } from '../../api/stats';
import { useAuth0 } from '@auth0/auth0-react';

const ProfileContainerComponent = () => {
	const { getAccessTokenSilently, logout } = useAuth0();
	const [isLoaded, setIsLoaded] = useState();
	const [workoutCount, setWorkoutCount] = useState(0);
	const [workoutPerformance, setWorkoutPerformance] = useState([]);
	const [error, setError] = useState();

	const { user } = useAuth0();
    const { 'https://hasiwod.carldanell.se/user_role': role } = user;

	useEffect(() => {
		const fetchData = async () => {
			try {
				const accessToken = await getAccessTokenSilently();

				const performance = await getWorkoutPerformance(accessToken, user?.email);
				const wods = await getWorkoutFrequency(accessToken, 1200, user?.email);
	
				let numberOfWods = 0;
				for (const workoutsPerProvider of Object.values(wods)) {
					for (let i = 0; i < workoutsPerProvider.length; i++) {
						const countPerMonth = workoutsPerProvider[i]?.count;
						numberOfWods += countPerMonth;
					}
				}
	
				setWorkoutCount(numberOfWods);
				setWorkoutPerformance(performance);
				setIsLoaded(true);
			} catch (e) {
				setError(e);
			}
		};
		fetchData()
		}, [getAccessTokenSilently, user?.email]);

	const calculateAveragePerformance = (arr) =>  {
		if(arr.length < 1) {
			return 0;
		}
		return Math.round(arr.reduce(( a, b) => a + b, 0 ) / arr.length * 100 + Number.EPSILON) / 100;
	}
	
	if(error) {
		return <p>{error.message}</p>
	}
	if (!isLoaded) {
		return <LoaderComponent text="Please wait while the Hasi is getting your page"/>
	} 
	return (
		<>
			<h3>Your Profile</h3>
			<div>
				<b>Name:</b> {user?.name} <br/>
				<b>Email:</b> {user?.email} <br/>
				<b>Role:</b> {role} <br/>
				You have completed <b>{workoutCount}</b> workouts. <br/>
				Your total workout performance is <b>{calculateAveragePerformance(workoutPerformance)}/5.</b> <br/>
				Your current workout performance is <b>{calculateAveragePerformance(workoutPerformance.slice(0,5))}/5.</b> <br/> <br/>
			</div>
			<br/><br/><br/>
			<button className="button logout" onClick={logout}>Logout</button>
		</>
	);     
}

export default ProfileContainerComponent;