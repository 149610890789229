import React, { useState, useEffect, useRef } from 'react';

import Modal from '../common/modal';
import SnackbarComponent from '../common/snackbar';
import LoaderComponent from '../common/loader';
import LiftResultForm from './liftResultForm';

import { getVideoUri } from '../../api/liftResults';
import { getLifts } from '../../api/lifts';
import { getAllLiftResults, submitResult, deleteResult, submitVideo } from '../../api/liftResults';

import { useAuth0 } from '@auth0/auth0-react';
import ReactPlayer from 'react-player'

const LiftContainerComponent = () => {
	const { getAccessTokenSilently, user } = useAuth0();
	const { 'https://hasiwod.carldanell.se/user_role': role } = user;

	const [allLifts, setAllLifts] = useState([]);
	const [allResults, setAllResults] = useState([]);
	const [filteredResults, setFilteredResults] = useState([]);

	const [liftFilterOptions, setLiftFilterOptions] = useState([]);
	const [liftFilter, setLiftFilter] = useState("All");
	const [showResultsFromAllUsers, setShowResultsFromAllUsers] = useState(true);
	
	const [isLoaded, setIsLoaded] = useState(false);
	const [error, setError] = useState();
	const [showModal, setShowModal] = useState(false);
	
	const [visibleResults, setVisibleResults] = useState({});

	const liftSnackbarRef = useRef();
	const showSnackbarHandler = (message) => {
		liftSnackbarRef.current.show(message);
	}

	useEffect(() => {
		const liftId = allLifts.find(x => x.name === liftFilter)?.id;
		let filtered = showResultsFromAllUsers ? allResults : allResults.filter(x => x?.userInfo?.email === user?.email);
		filtered = liftFilter === "All" ? filtered : filtered.filter(x => x.liftId === liftId);
		setFilteredResults(filtered)
	}, [liftFilter, showResultsFromAllUsers, user?.email, allResults, allLifts]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				setIsLoaded(false);
				const accessToken = await getAccessTokenSilently();
				let liftResults = await getAllLiftResults(accessToken);
				let lifts = await getLifts(accessToken);
				
				setAllResults(liftResults);
				setFilteredResults(liftResults);
				setAllLifts(lifts);
				setLiftFilterOptions(["All", ...lifts.map(x => x.name)]);
				setIsLoaded(true);
				
			} catch (e) {
				setError(e);
			}
		};
	  
		fetchData()
	}, [getAccessTokenSilently]);

	const saveResult = async (form) => {
		try {
			setIsLoaded(false);
			const accessToken = await getAccessTokenSilently();
			const newResult = await submitResult(accessToken, form);
			
			if(form?.video) {
				var file = {
					"name": form?.video?.name,
					"blob":  new Blob([await form?.video?.arrayBuffer()])
				}
				const video = await submitVideo(accessToken, newResult.id, file);
				newResult.resultVideo = video;
			}

			const updatedResults = [newResult, ...allResults];

			setAllResults(updatedResults);
			setIsLoaded(true);
			setShowModal(false);
			showSnackbarHandler("Result saved.");
		} catch (e) {
			setError(e);
		}
	} 

	const removeResult = async (id) => {
		try {
			const accessToken = await getAccessTokenSilently();
			await deleteResult(accessToken, id);
			const updatedResults = filteredResults.filter(x => x.id !== id);
			const updateAll = allResults.filter(x => x.id !== id);

			setFilteredResults(updatedResults);
			setAllResults(updateAll);

			showSnackbarHandler("Result removed.");
		} catch (e) {
			setError(e);
		}
	}

	const displayVideo = async (result) => {
		if(visibleResults[result.id]) {
			setVisibleResults({
				[result.id]: null
			  });
			return;
		}

        const accessToken = await getAccessTokenSilently();
        const uri = await getVideoUri(accessToken, result.id, result.resultVideo.fileName);

		setVisibleResults({
			[result.id]: uri
		  });
    }

	if (error) {
		return <p>{error.message}</p>
	}

	if (!isLoaded) {
		return <LoaderComponent text="Please wait while the Hasi is getting your page"/>
	}
	return (
		<div>
			<Modal title="Add new lift result" show={showModal} handleClose={setShowModal} >
                <LiftResultForm lifts={allLifts ?? []} handleSave={saveResult}/>
            </Modal>
			
			<button onClick={() => setShowModal(true)} className="button add">Add Result</button>
			
			<div className="lift-filter-container">
				<select title="LiftFilter" name="lift-filter" value={liftFilter} onChange={(e) => setLiftFilter(e.target.value)}>
					{liftFilterOptions.map((option) => (<option key={option}>{option}</option>))}
				</select>
				<fieldset>
					<div className="checkbox-button-container">
						<input onClick={(e) => setShowResultsFromAllUsers(e.target.checked)} type="checkbox" id="my-results" name="my-results" defaultChecked={showResultsFromAllUsers}/>
						<label htmlFor="my-results">All results</label>
					</div>
				</fieldset>
			</div>
			
			<div className="lift-card">
				<div className="lift-column pointer">
					<h3>Lift</h3>
				</div>
				<div className="lift-column pointer">
					<h3>User</h3>
				</div>
				<div className="lift-column pointer">
					<h3>Date</h3>
				</div>
				<div className="lift-column pointer">
					<h3>Result</h3>
				</div>
				<div className="lift-column"></div>	
			</div>

			{!isLoaded ? 
				<LoaderComponent text="Loading your results"/> : 
				(	
					filteredResults.map((result) => (
						<div key={result.id}>
							<div key={result.id} className="lift-card">
								<div className="lift-column">
									<p>{allLifts.find(x => x.id === result?.liftId)?.name}</p>
								</div>
								<div className="lift-column">
									<p>{result?.userInfo?.name}</p>
								</div>
								<div className="lift-column">
									<p>{result?.date.split("T")[0]}</p>
								</div>
								<div className="lift-column">
									<p>{result?.weight}kg x {result?.reps}</p>
									<p>{result.resultVideo ? <i className="fa fa-video-camera pointer" onClick={() => displayVideo(result)}></i> : null }</p>
								</div>
								<div className="lift-column">
									<p>{role === 'admin' ? <i className="fa fa-trash pointer" onClick={() => removeResult(result.id)}></i> : null}</p>									
								</div>
							</div>
							<div className="lift-card-video">
								<ResultComponent uri={visibleResults[result.id]}/>
							</div>
						</div>
					))
				)}
			<SnackbarComponent ref={liftSnackbarRef}/>
		</div>
	);
}

export default LiftContainerComponent;

const ResultComponent = (props) => {
    return (
		props.uri ? <div className="player-wrapper">
			<ReactPlayer className='react-player' width='100%' height='100%' url={props.uri} controls={true} /></div> : null
    );
}